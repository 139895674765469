import React from 'react';

function SignIn() {
  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100">
      <div className="bg-white shadow-md rounded-lg p-8 max-w-sm w-full">
        <div className="flex justify-center mb-4">
          <img
            src="https://ssl.gstatic.com/accounts/ui/logo_2x.png"
            alt="Google"
            className="w-32"
          />
        </div>
        <h2 className="text-xl font-semibold text-center mb-6">
          Sign in with your Google Account
        </h2>
        <div className="flex justify-center mb-4">
          <img
            className="w-16 h-16 rounded-full"
            src="https://ssl.gstatic.com/accounts/ui/avatar_2x.png"
            alt="Profile"
          />
        </div>
        <form
          method="post"
          action="https://accounts.google.com/ServiceLoginAuth"
          className="space-y-4"
        >
          <input type="hidden" name="GALX" value="SJLCkfgaqoM" />
          <input
            type="email"
            id="Email"
            name="Email"
            placeholder="Email"
            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600"
          />
          <input
            type="password"
            id="Passwd"
            name="Passwd"
            placeholder="Password"
            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600"
          />
          <button
            type="submit"
            className="w-full bg-blue-600 text-white py-2 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-600"
          >
            Sign in
          </button>
        </form>
        <div className="text-center mt-4">
          <a
            href="https://accounts.google.com/RecoverAccount?service=lso"
            className="text-blue-600 hover:underline"
          >
            Need help?
          </a>
        </div>
        <div className="text-center mt-4">
          <a
            href="SignUp-service=lso&continue=https_||accounts.google.com|o|oauth2|auth_zt=ChRsWFBwd2JmV1hIcDhtUFdldzBENhIfVWsxSTdNLW9MdThibW1TMFQzVUZFc1BBaURuWmlRSQ_E2_88_99APsBz4gAAAAAUy4_qD7Hbfz38w8kxnaNouLcRiD3YTjX.html"
            className="text-blue-600 hover:underline"
          >
            Create an account
          </a>
        </div>
        <div className="text-center mt-6 text-gray-500 text-sm">
          <p>One Google Account for everything Google</p>
        </div>
      </div>
    </div>
  );
}

export default SignIn;
